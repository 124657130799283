.editor-paragraph {
  margin-top: 0px;
  line-height: 20px;
  margin-bottom: 0px;
}

.editor-text-bold {
  font-weight: bold;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

a {
  color: #047b79;
}

a:visited {
  color: #027a77; /* Color for visited links */
}
