@font-face {
  font-family: "Gotham";
  src: url("./shared/font/Gotham-Light.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./shared/font/GothamMedium.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./shared/font/Gotham-Black.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Gotham";
  src: url("./shared/font/Gotham-Bold.otf");
  font-weight: 700;
  font-style: normal;
}

.picker-dialog-bg {
  opacity: 0.05 !important;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 2px;
}
